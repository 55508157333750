/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const TileWrapper = ({ children }) => (
  <div
    sx={{
      display: "flex",
      flexWrap: ["wrap", "nowrap"],
      justifyContent: "space-between",
    }}
  >
    {children}
  </div>
)

TileWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TileWrapper
