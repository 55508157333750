/** @jsx jsx */
import { Fragment } from "react"
import PropTypes from "prop-types"
import { jsx, Heading } from "theme-ui"

import { Container } from "components/layout"

const Title = ({ children, bg, color, as, icon }) => (
  <div
    sx={{
      bg,
      color,
      py: 3,
    }}
  >
    <Container>
      <div
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          "&>svg": {
            maxHeight: ["50px", null, "70px"],
            minHeight: ["50px", null, "70px"],
            width: "auto",
          },
        }}
      >
        <Heading
          as={as}
          sx={{
            color,
            my: 0,
            fontSize: [4, 5, 6],
          }}
        >
          {children}
        </Heading>
        {icon && <Fragment>{icon}</Fragment>}
      </div>
    </Container>
  </div>
)

Title.defaultProps = {
  as: "h2",
  bg: "secondary",
  color: "dark",
}

Title.propTypes = {
  as: PropTypes.string,
  bg: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
}

export default Title
