/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

const TileInfo = ({ children, bg, color }) => (
  <div
    sx={{
      alignItems: "center",
      bg,
      color,
      p: 3,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: ["50%", "25%"],
      "&>p": {
        textAlign: "center",
      },
      "&>p>span": {
        color,
      },
    }}
  >
    {children}
  </div>
)

TileInfo.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

export default TileInfo
