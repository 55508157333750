/** @jsx jsx */
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { jsx, Text } from "theme-ui"

const TileLink = ({ to, image, children }) => (
  <Link
    sx={{
      position: "relative",
      pb: 3,
      "&:hover > p": {
        bg: "primary",
        color: "background",
      },
    }}
    to={to}
  >
    <Img fluid={image} />
    <Text as="p" variant="tile">
      {children}
    </Text>
  </Link>
)

TileLink.propTypes = {
  to: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default TileLink
